@-webkit-keyframes skeleton-fade-in{
  0%{
    opacity:0; }
  100%{
    opacity:1; } }
@keyframes skeleton-fade-in{
  0%{
    opacity:0; }
  100%{
    opacity:1; } }
@keyframes skeleton-fade-in{
  0%{
    opacity:0; }
  100%{
    opacity:1; } }
.bp3-table-cell{
  display:inline-block;
  font-size:12px;
  height:20px;
  line-height:20px;
  padding:0 10px;
  -webkit-transition:color 300ms;
  transition:color 300ms; }
  .bp3-dark .bp3-table-cell:not([class*="bp3-intent-"]):not(.bp3-loading){
    color:#f5f8fa; }
  .bp3-table-cell.bp3-intent-primary{
    background-color:rgba(19, 124, 189, 0.1);
    color:#137cbd; }
  .bp3-dark .bp3-table-cell.bp3-intent-primary{
    background:rgba(19, 124, 189, 0.1);
    color:#2b95d6; }
  .bp3-table-cell.bp3-intent-success{
    background-color:rgba(15, 153, 96, 0.1);
    color:#0f9960; }
  .bp3-dark .bp3-table-cell.bp3-intent-success{
    background:rgba(15, 153, 96, 0.1);
    color:#15b371; }
  .bp3-table-cell.bp3-intent-warning{
    background-color:rgba(217, 130, 43, 0.1);
    color:#d9822b; }
  .bp3-dark .bp3-table-cell.bp3-intent-warning{
    background:rgba(217, 130, 43, 0.1);
    color:#f29d49; }
  .bp3-table-cell.bp3-intent-danger{
    background-color:rgba(219, 55, 55, 0.1);
    color:#db3737; }
  .bp3-dark .bp3-table-cell.bp3-intent-danger{
    background:rgba(219, 55, 55, 0.1);
    color:#f55656; }
  .bp3-table-editing-enabled .bp3-table-cell{
    cursor:text; }
  .bp3-table-selection-enabled .bp3-table-cell{
    cursor:cell; }
  .bp3-table-cell.bp3-table-truncated-cell{
    overflow:hidden; }
  .bp3-table-cell.bp3-large,
  .bp3-large .bp3-table-cell{
    font-size:14px;
    height:30px;
    line-height:30px; }
  .bp3-table-cell.bp3-loading{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center;
    color:transparent; }
  .bp3-table-cell:focus{
    outline:none; }

.bp3-table-cell-interactive{
  z-index:21; }

.bp3-table-striped .bp3-table-cell-ledger-even{
  background-color:#ffffff; }

.bp3-table-striped .bp3-table-cell-ledger-odd{
  background-color:#fafcfd; }

.bp3-dark .bp3-table-striped .bp3-table-cell-ledger-even{
  background-color:#293742; }

.bp3-dark .bp3-table-striped .bp3-table-cell-ledger-odd{
  background-color:#2d3c48; }

.bp3-table-editable-name input{
  height:20px; }

.bp3-table-editable-text{
  bottom:0;
  left:0;
  padding:0 10px;
  position:absolute;
  right:0;
  top:0; }
.bp3-table-null{
  color:rgba(92, 112, 128, 0.6); }

.bp3-table-truncated-value{
  left:10px;
  max-height:100%;
  overflow:hidden;
  position:absolute;
  right:35px;
  text-overflow:ellipsis;
  top:0; }

.bp3-table-truncated-format-text{
  left:10px;
  max-height:100%;
  overflow:hidden;
  position:absolute;
  right:10px;
  text-overflow:ellipsis;
  top:0; }

.bp3-table-truncated-popover-target{
  border-radius:3px;
  bottom:0;
  cursor:pointer;
  opacity:0.3;
  padding:0 5px;
  position:absolute;
  right:5px;
  text-align:center;
  top:0; }
  .bp3-table-truncated-popover-target .bp3-icon-standard{
    line-height:20px; }
  .bp3-table-truncated-popover-target.bp3-popover-open{
    opacity:1; }
    .bp3-table-truncated-popover-target.bp3-popover-open .bp3-icon-standard{
      color:#137cbd; }
  .bp3-table-truncated-popover-target:hover{
    opacity:1; }

.bp3-table-truncated-popover{
  font-family:monospace;
  max-height:300px;
  max-width:600px;
  min-width:200px;
  overflow:auto;
  padding:10px 10px; }

.bp3-table-popover-whitespace-pre{
  white-space:pre; }

.bp3-table-popover-whitespace-normal{
  white-space:normal; }
.bp3-table-container{
  -webkit-box-shadow:0 0 0 1px rgba(16, 22, 26, 0.15);
          box-shadow:0 0 0 1px rgba(16, 22, 26, 0.15); }

.bp3-table-menu{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-header{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.15);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.15); }

.bp3-table-cell{
  -webkit-box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-horizontal-cell-divider{
  -webkit-box-shadow:inset 0 1px 0 rgba(16, 22, 26, 0.15);
          box-shadow:inset 0 1px 0 rgba(16, 22, 26, 0.15);
  height:1px; }

.bp3-table-column-headers .bp3-table-header{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }
  .bp3-table-column-headers .bp3-table-header::before{
    bottom:0;
    right:1px; }

.bp3-table-row-headers .bp3-table-header{
  -webkit-box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }
  .bp3-table-row-headers .bp3-table-header::before{
    bottom:1px;
    right:0; }

.bp3-table-body .bp3-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-body .bp3-table-last-in-column{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-body .bp3-table-last-in-row.bp3-table-last-in-column{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-column.bp3-table-last-in-row{
  -webkit-box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-row{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }
  .bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-row::before{
    bottom:0;
    right:3px; }

.bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }
  .bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-column::before{
    bottom:3px;
    right:0; }

.bp3-table-quadrant-left .bp3-table-cell.bp3-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top .bp3-table-cell.bp3-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
          box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-dark .bp3-table-container{
  -webkit-box-shadow:0 0 0 1px rgba(16, 22, 26, 0.4);
          box-shadow:0 0 0 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-menu{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-header{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.4);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-cell{
  -webkit-box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-horizontal-cell-divider{
  -webkit-box-shadow:inset 0 1px 0 rgba(16, 22, 26, 0.4);
          box-shadow:inset 0 1px 0 rgba(16, 22, 26, 0.4);
  height:1px; }

.bp3-dark .bp3-table-column-headers .bp3-table-header{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-table-column-headers .bp3-table-header::before{
    bottom:0;
    right:1px; }

.bp3-dark .bp3-table-row-headers .bp3-table-header{
  -webkit-box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-table-row-headers .bp3-table-header::before{
    bottom:1px;
    right:0; }

.bp3-dark .bp3-table-body .bp3-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-body .bp3-table-last-in-column{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-body .bp3-table-last-in-row.bp3-table-last-in-column{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-column.bp3-table-last-in-row{
  -webkit-box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-row{
  -webkit-box-shadow:0 1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:0 1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-row::before{
    bottom:0;
    right:3px; }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-column::before{
    bottom:3px;
    right:0; }

.bp3-dark .bp3-table-quadrant-left .bp3-table-cell.bp3-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top .bp3-table-cell.bp3-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4);
          box-shadow:inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }
@keyframes skeleton-fade-in{
  0%{
    opacity:0; }
  100%{
    opacity:1; } }
.bp3-table-header{
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start;
  -webkit-box-flex:1;
      -ms-flex:1 1 auto;
          flex:1 1 auto;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  position:relative;
  text-overflow:ellipsis;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none; }
  .bp3-table-header::before{
    content:"";
    display:block;
    left:0;
    position:absolute;
    top:0; }
  .bp3-table-header .bp3-table-row-name,
  .bp3-table-header .bp3-table-column-name,
  .bp3-table-header .bp3-table-header-content{
    position:relative;
    width:100%; }
  .bp3-table-selection-enabled .bp3-table-header:hover::before, .bp3-table-header.bp3-table-header-active::before{
    background-color:#e1e8ed; }
    .bp3-dark .bp3-table-selection-enabled .bp3-table-header:hover::before, .bp3-dark .bp3-table-header.bp3-table-header-active::before{
      background-color:#394b59; }
  .bp3-table-header.bp3-table-header-selected::before{
    background-image:-webkit-gradient(linear, left top, right top, from(rgba(19, 124, 189, 0.1)), to(rgba(19, 124, 189, 0.1)));
    background-image:linear-gradient(90deg, rgba(19, 124, 189, 0.1), rgba(19, 124, 189, 0.1)); }

.bp3-table-th-menu-container{
  -ms-flex-negative:0;
      flex-shrink:0;
  opacity:0;
  position:absolute;
  right:1px;
  text-align:right; }
  .bp3-table-header:hover .bp3-table-th-menu-container,
  .bp3-table-header-active .bp3-table-th-menu-container, .bp3-table-th-menu-container.bp3-table-th-menu-open{
    opacity:1; }
  .bp3-table-interaction-bar .bp3-table-th-menu-container{
    line-height:20px; }

.bp3-table-th-menu-container-background{
  height:30px;
  pointer-events:none;
  position:absolute;
  right:0;
  top:0;
  width:50px; }
  .bp3-table-interaction-bar .bp3-table-th-menu-container-background{
    height:20px; }
  .bp3-table-th-menu-open .bp3-table-th-menu-container-background{
    background-image:-webkit-gradient(linear, left top, right top, from(rgba(245, 248, 250, 0)), color-stop(50%, #f5f8fa));
    background-image:linear-gradient(90deg, rgba(245, 248, 250, 0) 0%, #f5f8fa 50%); }
    .bp3-dark .bp3-table-th-menu-open .bp3-table-th-menu-container-background{
      background-image:-webkit-gradient(linear, left top, right top, from(rgba(48, 64, 77, 0)), color-stop(50%, #30404d));
      background-image:linear-gradient(90deg, rgba(48, 64, 77, 0) 0%, #30404d 50%); }
  .bp3-table-header:hover .bp3-table-th-menu-container-background,
  .bp3-table-header-active .bp3-table-th-menu-container-background,
  .bp3-table-th-menu-open .bp3-table-th-menu-container-background{
    background-image:-webkit-gradient(linear, left top, right top, from(rgba(245, 248, 250, 0)), color-stop(50%, #f5f8fa));
    background-image:linear-gradient(90deg, rgba(245, 248, 250, 0) 0%, #f5f8fa 50%); }
    .bp3-dark .bp3-table-header:hover .bp3-table-th-menu-container-background, .bp3-dark
    .bp3-table-header-active .bp3-table-th-menu-container-background, .bp3-dark
    .bp3-table-th-menu-open .bp3-table-th-menu-container-background{
      background-image:-webkit-gradient(linear, left top, right top, from(rgba(48, 64, 77, 0)), color-stop(50%, #30404d));
      background-image:linear-gradient(90deg, rgba(48, 64, 77, 0) 0%, #30404d 50%); }
  .bp3-table-selection-enabled .bp3-table-header:hover .bp3-table-th-menu-container-background,
  .bp3-table-selection-enabled .bp3-table-header-active .bp3-table-th-menu-container-background{
    background-image:-webkit-gradient(linear, left top, right top, from(rgba(225, 232, 237, 0)), color-stop(50%, #e1e8ed));
    background-image:linear-gradient(90deg, rgba(225, 232, 237, 0) 0%, #e1e8ed 50%); }
    .bp3-dark .bp3-table-selection-enabled .bp3-table-header:hover .bp3-table-th-menu-container-background, .bp3-dark
    .bp3-table-selection-enabled .bp3-table-header-active .bp3-table-th-menu-container-background{
      background-image:-webkit-gradient(linear, left top, right top, from(rgba(57, 75, 89, 0)), color-stop(50%, #394b59));
      background-image:linear-gradient(90deg, rgba(57, 75, 89, 0) 0%, #394b59 50%); }
  .bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected .bp3-table-th-menu-container-background{
    background-image:-webkit-gradient(linear, left top, right top, from(rgba(222, 236, 244, 0)), color-stop(50%, #deecf4));
    background-image:linear-gradient(90deg, rgba(222, 236, 244, 0) 0%, #deecf4 50%); }
    .bp3-dark .bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected .bp3-table-th-menu-container-background{
      background-image:-webkit-gradient(linear, left top, right top, from(rgba(45, 70, 88, 0)), color-stop(50%, #2d4658));
      background-image:linear-gradient(90deg, rgba(45, 70, 88, 0) 0%, #2d4658 50%); }
  .bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected:hover .bp3-table-th-menu-container-background{
    background-image:-webkit-gradient(linear, left top, right top, from(rgba(204, 221, 232, 0)), color-stop(50%, #ccdde8));
    background-image:linear-gradient(90deg, rgba(204, 221, 232, 0) 0%, #ccdde8 50%); }
    .bp3-dark .bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected:hover .bp3-table-th-menu-container-background{
      background-image:-webkit-gradient(linear, left top, right top, from(rgba(53, 80, 99, 0)), color-stop(50%, #355063));
      background-image:linear-gradient(90deg, rgba(53, 80, 99, 0) 0%, #355063 50%); }

.bp3-table-th-menu{
  cursor:pointer;
  height:30px;
  position:relative;
  width:30px; }
  .bp3-table-interaction-bar .bp3-table-th-menu{
    height:20px;
    right:1px;
    text-align:center;
    width:20px; }
    .bp3-table-interaction-bar .bp3-table-th-menu .bp3-icon{
      margin:2px;
      margin-left:3px;
      vertical-align:top; }
  .bp3-table-th-menu .bp3-icon{
    background-color:#f5f8fa;
    border-radius:3px;
    -webkit-box-shadow:inset 0 0 0 1px rgba(16, 22, 26, 0.2);
            box-shadow:inset 0 0 0 1px rgba(16, 22, 26, 0.2);
    color:#5c7080;
    margin-right:7px;
    margin-top:7px; }
    .bp3-table-selection-enabled .bp3-table-th-menu .bp3-icon{
      background-color:#e1e8ed; }
    .bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected .bp3-table-th-menu .bp3-icon{
      background-color:linear-gradient(90deg, rgba(222, 236, 244, 0) 0%, #deecf4 50%); }
    .bp3-dark .bp3-table-th-menu .bp3-icon{
      background-color:#30404d;
      -webkit-box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2);
              box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2);
      color:#a7b6c2; }
    .bp3-dark .bp3-table-selection-enabled .bp3-table-th-menu .bp3-icon{
      background-color:#394b59; }
    .bp3-dark .bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected .bp3-table-th-menu .bp3-icon{
      background-color:linear-gradient(90deg, rgba(45, 70, 88, 0) 0%, #2d4658 50%); }
  .bp3-table-th-menu:hover .bp3-icon{
    -webkit-box-shadow:inset 0 0 0 1px rgba(16, 22, 26, 0.4);
            box-shadow:inset 0 0 0 1px rgba(16, 22, 26, 0.4);
    color:#182026; }
    .bp3-dark .bp3-table-th-menu:hover .bp3-icon{
      -webkit-box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.4);
              box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.4);
      color:#f5f8fa; }
  .bp3-table-th-menu.bp3-popover-open .bp3-icon{
    background-color:#137cbd;
    -webkit-box-shadow:none;
            box-shadow:none;
    color:#ffffff; }
    .bp3-dark .bp3-table-th-menu.bp3-popover-open .bp3-icon{
      background-color:#137cbd;
      -webkit-box-shadow:none;
              box-shadow:none;
      color:#ffffff; }

.bp3-table-thead{
  display:block;
  white-space:nowrap; }

.bp3-table-column-header-tr{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex; }
  .bp3-table-column-header-tr .bp3-table-header{
    -webkit-box-flex:0;
        -ms-flex:0 0;
            flex:0 0; }

.bp3-table-column-headers .bp3-table-interaction-bar{
  height:20px;
  position:relative; }

.bp3-table-column-headers .bp3-table-header{
  line-height:30px;
  min-height:30px;
  vertical-align:top; }

.bp3-table-row-headers .bp3-table-header{
  line-height:20px;
  min-width:30px;
  overflow:hidden; }

.bp3-table-column-name-text,
.bp3-table-row-name-text{
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
  pointer-events:none; }

.bp3-table-truncated-text{
  max-height:100%;
  overflow:hidden;
  text-overflow:ellipsis; }

.bp3-table-no-wrap-text{
  white-space:nowrap; }

.bp3-table-column-name-text{
  padding:0 10px; }

.bp3-table-editable-name{
  display:block;
  pointer-events:all; }
  .bp3-table-editable-name.bp3-editable-text::before{
    border-radius:0;
    bottom:0;
    left:-11px;
    right:-10px;
    top:-1px; }
  .bp3-table-editable-name.bp3-editable-text:not(.bp3-editable-editing)::before{
    -webkit-box-shadow:none;
            box-shadow:none; }
  .bp3-table-editable-name.bp3-editable-text.bp3-editable-editing::before{
    bottom:1px;
    cursor:text;
    left:-10px;
    right:-9px;
    top:0px; }
  .bp3-table-editable-name.bp3-editable-text.bp3-editable-editing.bp3-table-editable-text::before{
    left:0px;
    right:1px; }
  .bp3-table-column-name .bp3-table-editable-name.bp3-editable-text::before{
    bottom:-1px; }
  .bp3-table-column-name .bp3-table-editable-name.bp3-editable-text.bp3-editable-editing::before{
    bottom:0; }

.bp3-table-column-name-text .bp3-table-editable-name input{
  height:30px; }

.bp3-table-column-name{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  font-size:14px;
  text-align:left; }

.bp3-table-row-name{
  display:block;
  font-size:12px;
  padding:0 5px;
  text-align:right; }

.bp3-table-header-content{
  font-size:14px;
  white-space:normal; }
  .bp3-table-header-content .is-searchable:not(.is-focused) > .Select-control{
    background:none;
    -webkit-box-shadow:none;
            box-shadow:none; }
  .bp3-table-header-content .is-searchable > .Select-control{
    border-radius:0; }
    .bp3-table-header-content .is-searchable > .Select-control .Select-value{
      cursor:pointer; }
  .bp3-table-header-content .Select-value{
    right:-1px; }

.bp3-table-column-name,
.bp3-table-row-name{
  -webkit-transition:color 300ms;
  transition:color 300ms; }

.bp3-table-header.bp3-loading{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center; }
  .bp3-table-header.bp3-loading .bp3-table-column-name,
  .bp3-table-header.bp3-loading .bp3-table-row-name{
    -webkit-box-flex:1;
        -ms-flex:1;
            flex:1; }
  .bp3-table-header.bp3-loading .bp3-table-column-name-text{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center;
    padding:10px; }
    .bp3-table-header.bp3-loading .bp3-table-column-name-text .bp3-skeleton{
      height:8px; }
  .bp3-table-header.bp3-loading .bp3-table-row-name{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center; }
.bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header{
  cursor:s-resize; }
  .bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header.bp3-table-header-reorderable{
    cursor:-webkit-grab;
    cursor:grab; }
    .bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header.bp3-table-header-reorderable:active{
      cursor:-webkit-grabbing;
      cursor:grabbing; }
    .bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header.bp3-table-header-reorderable .bp3-table-interaction-bar{
      cursor:-webkit-grab;
      cursor:grab; }
      .bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header.bp3-table-header-reorderable .bp3-table-interaction-bar:active{
        cursor:-webkit-grabbing;
        cursor:grabbing; }

.bp3-table-selection-enabled.bp3-table-row-headers .bp3-table-header{
  cursor:e-resize; }
  .bp3-table-selection-enabled.bp3-table-row-headers .bp3-table-header.bp3-table-header-reorderable{
    cursor:-webkit-grab;
    cursor:grab; }
    .bp3-table-selection-enabled.bp3-table-row-headers .bp3-table-header.bp3-table-header-reorderable:active{
      cursor:-webkit-grabbing;
      cursor:grabbing; }
    .bp3-table-selection-enabled.bp3-table-row-headers .bp3-table-header.bp3-table-header-reorderable .bp3-table-interaction-bar{
      cursor:-webkit-grab;
      cursor:grab; }
      .bp3-table-selection-enabled.bp3-table-row-headers .bp3-table-header.bp3-table-header-reorderable .bp3-table-interaction-bar:active{
        cursor:-webkit-grabbing;
        cursor:grabbing; }

.bp3-table-selection-enabled.bp3-table-menu{
  cursor:se-resize; }

.bp3-table-selection-enabled .bp3-editable-text::before,
.bp3-table-selection-enabled .bp3-editable-content{
  cursor:cell; }
.bp3-table-column-header-cell.bp3-table-has-reorder-handle:not(.bp3-table-has-interaction-bar) .bp3-table-column-name-text{
  padding-left:22px; }

.bp3-table-column-header-cell.bp3-table-has-reorder-handle:not(.bp3-table-has-interaction-bar) .bp3-table-editable-name::before{
  left:-22px; }

.bp3-table-reorder-handle-target{
  cursor:-webkit-grab;
  cursor:grab;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  bottom:0;
  color:rgba(92, 112, 128, 0.6);
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  left:0;
  position:absolute;
  top:0;
  width:22px; }
  .bp3-table-reorder-handle-target:active{
    cursor:-webkit-grabbing;
    cursor:grabbing; }
  .bp3-table-reorder-handle-target:hover{
    color:#182026; }
  .bp3-table-reorder-handle-target:active{
    color:#137cbd; }
  .bp3-dark .bp3-table-reorder-handle-target{
    color:rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-table-reorder-handle-target:hover{
      color:#f5f8fa; }
    .bp3-dark .bp3-table-reorder-handle-target:active{
      color:#137cbd; }

.bp3-table-reorder-handle{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex; }

.bp3-table-resize-handle-target{
  opacity:0;
  position:absolute;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  z-index:20; }
  .bp3-table-resize-handle-target:hover, .bp3-table-resize-handle-target.bp3-table-dragging{
    opacity:1; }
  .bp3-table-resize-handle-target.bp3-table-resize-vertical{
    bottom:-1px;
    cursor:ew-resize;
    right:0;
    top:0;
    width:5px; }
  .bp3-table-resize-handle-target.bp3-table-resize-horizontal{
    bottom:0;
    cursor:ns-resize;
    height:5px;
    left:0;
    right:-1px; }

.bp3-table-resize-handle{
  background-color:#137cbd;
  position:absolute;
  z-index:20; }
  .bp3-table-resize-handle.bp3-table-dragging{
    background-color:#137cbd; }

.bp3-table-resize-vertical .bp3-table-resize-handle{
  bottom:0;
  left:2px;
  top:0;
  width:3px; }

.bp3-table-resize-horizontal .bp3-table-resize-handle{
  height:3px;
  left:0;
  right:0;
  top:2px; }

.bp3-table-resize-guides .bp3-table-horizontal-guide{
  background-color:#137cbd;
  height:3px;
  margin-top:-3px; }
  .bp3-table-resize-guides .bp3-table-horizontal-guide.bp3-table-horizontal-guide-flush-top{
    margin-top:0; }

.bp3-table-resize-guides .bp3-table-vertical-guide{
  background-color:#137cbd;
  margin-left:-3px;
  width:3px; }
  .bp3-table-resize-guides .bp3-table-vertical-guide.bp3-table-vertical-guide-flush-left{
    margin-left:0; }
.bp3-table-overlay-layer{
  bottom:0;
  left:0;
  overflow:hidden;
  pointer-events:none;
  position:absolute;
  right:0;
  top:0;
  z-index:20; }

.bp3-table-overlay{
  position:absolute; }

.bp3-table-region{
  background-color:rgba(138, 155, 168, 0.1);
  border:1px solid #8a9ba8; }

.bp3-table-selection-region{
  background-color:rgba(19, 124, 189, 0.1);
  border:1px solid #137cbd; }
  .bp3-table-column-headers .bp3-table-selection-region{
    background-color:transparent; }
  .bp3-table-row-headers .bp3-table-selection-region{
    background-color:transparent; }

.bp3-table-focus-region{
  border:2px solid #137cbd; }

.bp3-table-column-headers .bp3-table-region{
  border-bottom:none; }

.bp3-table-row-headers .bp3-table-region{
  border-right:none; }

.bp3-table-vertical-guide{
  bottom:0;
  top:0; }

.bp3-table-horizontal-guide{
  left:0;
  right:0; }

.bp3-table-reordering-cursor-overlay{
  cursor:-webkit-grabbing;
  cursor:grabbing; }
  .bp3-table-reordering .bp3-table-reordering-cursor-overlay{
    pointer-events:all; }
.bp3-table-quadrant-stack{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  height:100%;
  position:relative; }

.bp3-table-quadrant{
  background:#f5f8fa;
  left:0;
  overflow:hidden;
  position:absolute;
  top:0; }
  .bp3-dark .bp3-table-quadrant{
    background-color:#30404d; }

.bp3-table-quadrant-scroll-container{
  -webkit-transform:translateZ(0);
          transform:translateZ(0);
  bottom:0;
  left:0;
  overflow:auto;
  position:relative;
  right:0;
  top:0;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none; }
  .bp3-table-no-vertical-scroll .bp3-table-quadrant-scroll-container{
    overflow-y:hidden; }
  .bp3-table-no-horizontal-scroll .bp3-table-quadrant-scroll-container{
    overflow-x:hidden; }

.bp3-table-quadrant-body-container{
  position:relative; }

.bp3-table-quadrant-main{
  height:100%;
  left:auto;
  position:relative;
  top:auto;
  width:100%;
  z-index:0; }
  .bp3-table-quadrant-main .bp3-table-quadrant-scroll-container{
    height:100%;
    width:100%; }
  .bp3-table-quadrant-main .bp3-table-cell-client{
    background:#ffffff; }

.bp3-table-quadrant-top{
  right:0;
  z-index:1; }
  .bp3-table-quadrant-top .bp3-table-quadrant-scroll-container{
    bottom:-20px;
    overflow-y:hidden; }

.bp3-table-quadrant-left{
  bottom:0;
  -webkit-transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  z-index:2; }
  .bp3-table-quadrant-left .bp3-table-quadrant-scroll-container{
    bottom:0;
    height:auto;
    overflow-x:hidden;
    position:absolute;
    right:-20px;
    top:0; }
  .bp3-table-quadrant-left .bp3-table-body-virtual-client{
    min-width:1px; }

.bp3-table-quadrant-top-left{
  -webkit-transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  z-index:3; }
  .bp3-table-quadrant-top-left .bp3-table-quadrant-scroll-container{
    bottom:-20px;
    overflow-x:hidden;
    overflow-y:hidden;
    right:-20px; }
  .bp3-table-quadrant-top-left .bp3-table-body-virtual-client{
    min-width:1px; }

.bp3-table-container{
  -webkit-transform:translateZ(0);
          transform:translateZ(0);
  background-color:#f5f8fa;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  height:100%;
  max-height:100%;
  max-width:100%;
  min-height:60px;
  overflow:hidden; }
  .bp3-dark .bp3-table-container{
    background-color:#30404d; }
  .bp3-table-container .bp3-loading{
    color:transparent; }
    .bp3-table-container .bp3-loading .bp3-skeleton{
      -webkit-animation:300ms linear forwards skeleton-fade-in, 1000ms linear infinite alternate skeleton-glow;
              animation:300ms linear forwards skeleton-fade-in, 1000ms linear infinite alternate skeleton-glow;
      -webkit-animation-delay:0s, 300ms;
              animation-delay:0s, 300ms;
      height:5px;
      opacity:0; }

.bp3-table-top-container{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:0;
      -ms-flex:0 0 auto;
          flex:0 0 auto;
  min-height:0%; }
  .bp3-table-container.bp3-table-no-rows .bp3-table-top-container{
    padding-bottom:1px; }

.bp3-table-bottom-container{
  color:#182026;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:1;
      -ms-flex:1 1 auto;
          flex:1 1 auto;
  height:100%;
  min-height:0%; }
  .bp3-dark .bp3-table-bottom-container{
    color:#f5f8fa; }

.bp3-table-menu{
  background-color:#f5f8fa;
  -webkit-box-flex:0;
      -ms-flex:0 0 auto;
          flex:0 0 auto;
  position:relative;
  z-index:13; }
  .bp3-dark .bp3-table-menu{
    background-color:#30404d; }

.bp3-table-column-headers{
  background-color:#f5f8fa;
  color:#182026;
  display:block;
  position:relative;
  z-index:11; }
  .bp3-dark .bp3-table-column-headers{
    background-color:#30404d;
    color:#f5f8fa; }

.bp3-table-row-headers{
  background-color:#f5f8fa;
  color:#5c7080;
  -webkit-box-flex:0;
      -ms-flex:0 0 auto;
          flex:0 0 auto;
  position:relative;
  -webkit-transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  z-index:12; }
  .bp3-dark .bp3-table-row-headers{
    background-color:#30404d;
    color:#a7b6c2; }

.bp3-table-body{
  -webkit-box-flex:1;
      -ms-flex:1 1 100%;
          flex:1 1 100%;
  overflow:scroll;
  position:relative;
  z-index:10; }

.bp3-table-body-virtual-client{
  position:relative; }

.bp3-table-cell-client{
  background:#ffffff; }
  .bp3-dark .bp3-table-cell-client{
    background:#293742; }

.bp3-table-tbody{
  display:block;
  white-space:nowrap; }

.bp3-table-cell{
  display:inline-block; }

.bp3-table-no-layout{
  display:inline-block;
  position:absolute; }

.bp3-table-scrollbar-measure{
  height:100px;
  overflow:scroll;
  position:absolute;
  top:-9999px;
  width:100px; }
/*# sourceMappingURL=table.css.map */